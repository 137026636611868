<template>
  <div class="rounded-lg mapsearchfilter p-2">
    <div class="row">
      <!-- Basic controls -->
      <div class="col-12">
        <div class="mapsearchfilter__item">
          <div class="h6 d-none d-lg-block">
            {{ capitalize(trans('map.filters.localization')) }}
          </div>

          <!-- Country switcher -->
          <div class="d-flex">
            <div
              style="width: 5em"
              class="d-inline-block"
            >
              <country-switcher />
            </div>
            <search-where
              id="form_where"
              :initial-value="zoneName"
              :country-code="countryCode"
              class="border rounded"
              @input="handleWhereInput"
            />
          </div>
        </div>

        <div class="mapsearchfilter__item mt-2">
          <div class="h6 d-none d-lg-block">Sports</div>
          <search-what
            :initial-value="sport ? [{ id: sport, text: formattedSport }] : null"
            style="flex-grow: 1"
            class="rounded bg-white"
            @input="handleWhatInput"
          />
        </div>

        <div class="mapsearchfilter__item mt-2">
          <div class="h6 d-none d-lg-block">Date</div>
          <search-when
            v-model="when"
            @input="handleWhenInput"
          />
        </div>
      </div>
      <!-- /Basic controls -->
    </div>

    <!-- Full filters -->
    <div
      class="d-lg-block"
      :class="{ 'd-none': !isFiltersOpen && !filtersAreOnTheSide, 'p-3 bg-white': isFiltersOpen }"
    >
      <div
        v-if="isWorkComplete"
        class="mapsearchfilter__item"
      >
        <div
          v-b-toggle.collapse-3
          class="h6"
        >
          {{ capitalize(trans('general.activities')) }}
          <i
            class="fa text-gray ml-1"
            :class="`fa-caret-${true ? 'down' : 'up'}`"
          />
        </div>
        <b-collapse
          id="collapse-3"
          class="mt-2"
          visible
        >
          <b-form-checkbox-group
            v-model="activities"
            multiple
            stacked
            :options="activitiesOptions"
          />
        </b-collapse>
      </div>

      <div
        v-if="isWorkComplete"
        class="mapsearchfilter__item"
      >
        <div
          v-b-toggle.collapse-4
          class="h6"
        >
          {{ capitalize(trans('general.fields')) }}
          <i
            class="fa text-gray ml-1"
            :class="`fa-caret-${true ? 'down' : 'up'}`"
          />
        </div>
        <b-collapse
          id="collapse-4"
          class="mt-2"
          visible
        >
          <b-form-checkbox-group
            v-model="fieldType"
            multiple
            stacked
            :options="fieldTypeOptions"
          />
        </b-collapse>
      </div>

      <!-- <div class="mapsearchfilter__item">
        <div v-b-toggle.collapse-5 class="h6">
          {{ capitalize(trans('general.booking')) }}
          <i class="fa text-gray ml-1" :class="`fa-caret-${true ? 'down' : 'up'}`" />
        </div>
        <b-collapse id="collapse-5" class="mt-2" visible>
          <b-form-checkbox-group
            v-model="bookingType"
            multiple
            stacked
            :options="bookingTypeOptions"
          />
        </b-collapse>
      </div> -->
    </div>
    <!-- /Full filters -->
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapGetters } from 'vuex';
import SearchWhere from '../SearchWhere.vue';
import SearchWhat from '../SearchWhat.vue';
import CountrySwitcher from '../../common/CountrySwitcher.vue';
import { countryCodeToCountrySlug } from '../../../helpers/country';
import SearchWhen from '../SearchWhen.vue';

export default {
  name: 'MapSearchFilters',
  components: {
    CountrySwitcher,
    SearchWhere,
    SearchWhat,
    SearchWhen,
  },
  props: {
    countryCode: { type: String, required: true },
  },
  emits: ['update'],
  data() {
    return {
      isWorkComplete: false,
      // localization: null,
      bookingType: [],
      fieldType: [],
      activities: [],
      bookingTypeOptions: [
        { text: 'sportfinder', value: 'sportfinder' },
        { text: 'center', value: 'center' },
      ],
      isFiltersOpen: false,
    };
  },
  computed: {
    ...mapFields('map', ['sport', 'when', 'zoneName', 'zone']),
    ...mapGetters('map', ['queryString']),
    formattedSport() {
      return this.sport ? this.$store.state.sports[this.sport] : null;
    },
    fieldTypeOptions() {
      return [{ text: 'test', value: 1 }];
    },
    activitiesOptions() {
      return [{ text: 'test', value: 1 }];
    },
    filtersAreOnTheSide() {
      return window.innerWidth > 980;
    },
  },
  methods: {
    async handleWhereInput({ zone, geocoding }) {
      // console.dir('handleWhereInput', zone, geocoding);

      this.$store.dispatch('map/prepareNavigation');
      this.$store.commit('map/resetLocalization');
      this.$store.commit('map/setIsLoading', true);

      this.zoneName = geocoding.name;
      this.zone = zone ?? null;

      this.$store.commit('map/setGeocoding', geocoding);

      const t = new URL(
        Routing.generate('spartan.website.map', {
          _locale: this.spflocale,
          item1: countryCodeToCountrySlug(this.countryCode),
        }),
        window.location.origin
      );
      t.search = this.queryString;
      // console.dir(t);
      window.history.replaceState(null, '', t);
      this.$emit('update');
    },
    async handleWhatInput(v) {
      // console.dir('handleWhatInput', v);

      await this.$store.dispatch('map/prepareNavigation');
      this.$store.dispatch('map/handleSportChange', v);
      window.history.replaceState(null, '', this.queryString);
      this.$emit('update');
    },
    async handleWhenInput(v) {
      // console.dir('handleWhenInput', v);

      await this.$store.dispatch('map/prepareNavigation');
      this.$store.commit('map/setIsLoading', true);
      this.$store.commit('map/setWhen', v);
      window.history.replaceState(null, '', this.queryString);
      this.$emit('update');
    },
  },
};
</script>
